
import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonTitle, IonIcon, IonButton, modalController } from "@ionic/vue";
import { arrowBackOutline } from "ionicons/icons";

import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";

import { openToast } from "@/services/toast";

export default {
    name: "ModalFirmaIntervento",
    props: {
        data: {
            type: String,
            required: true,
        },
        firma: {
            type: String,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonToolbar,
        IonButtons,
        IonTitle,
        IonIcon,
        IonButton,
    },
    setup(props) {
        const route = useRoute();
        const interventoID = route.params.id;
        const hasSigned = ref(false); // Check if client has signed, otherwise don't save
        const canvas = ref<HTMLCanvasElement | null>(null);
        const isDrawing = ref(false);
        const lastX = ref(0);
        const lastY = ref(0);
        let ctx = null;
        const isCanvasTouched = ref(false);
        const firmaDaCancellare = ref(false);

        let reference = "";
        if (props.data === "tecnico") {
            reference = "tecnico";
        } else {
            reference = "cliente";
        }

        function populateCanvas() {
            if (props.firma && props.firma.length !== 0) {
                const sign = new Image();
                const ctx = canvas.value.getContext("2d");
                sign.onload = function() {
                    ctx.drawImage(sign, 0, 0);
                };
                sign.src = `data:image/png;base64,${props.firma}`;
            }
        }

        const closeModal = async () => {
            if (firmaDaCancellare.value) {
                await modalController.dismiss({ da_cancellare: true, tipo: reference });
            } else {
                modalController.dismiss();
            }
        };

        function getMousePos(event) {
            const canvasElement = canvas.value;
            const rect = canvasElement.getBoundingClientRect();
            let clientX, clientY;

            if (event.touches && event.touches.length > 0) {
                clientX = event.touches[0].clientX;
                clientY = event.touches[0].clientY;
            } else {
                clientX = event.clientX;
                clientY = event.clientY;
            }

            return [clientX - rect.left, clientY - rect.top];
        }

        function startDrawing(event) {
            isCanvasTouched.value = true;
            isDrawing.value = true;
            [lastX.value, lastY.value] = getMousePos(event);
        }

        function stopDrawing() {
            isDrawing.value = false;
        }

        function draw(event) {
            if (!isDrawing.value) return;

            const [x, y] = getMousePos(event);

            ctx.beginPath();
            ctx.moveTo(lastX.value, lastY.value);
            ctx.lineTo(x, y);
            ctx.stroke();

            [lastX.value, lastY.value] = [x, y];
        }

        function clearCanvas() {
            ctx = canvas.value.getContext("2d");
            ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);
            hasSigned.value = false;
            isCanvasTouched.value = false;
            firmaDaCancellare.value = true;
        }

        function getCanvasContent() {
            const dataURL = canvas.value.toDataURL();
            return dataURL;
        }

        async function closeModalOnSubmit() {
            if (isCanvasTouched.value) {
                const sign = getCanvasContent().replace("data:image/png;base64,", "");
                if (sign) {
                    await modalController.dismiss({ firma: sign, tipo: reference });
                } else {
                    await modalController.dismiss();
                }
            } else {
                openToast("Non puoi confermare senza aver firmato", "toast_danger");
            }
        }

        function confermaFirme() {
            const sign = getCanvasContent().replace("data:image/png;base64,", "");
            if (sign) {
                closeModalOnSubmit();
            }
        }

        function setCanvasSize() {
            const canvasElement = canvas.value;
            canvasElement.width = window.innerWidth;
            canvasElement.height = window.innerHeight;
            ctx = canvasElement.getContext("2d");
            ctx.strokeStyle = "#000";
            ctx.lineJoin = "round";
            ctx.lineCap = "round";
            ctx.lineWidth = 5;
        }

        onMounted(() => {
            setCanvasSize();
            populateCanvas();
        });

        return {
            canvas,
            startDrawing,
            stopDrawing,
            draw,
            clearCanvas,
            getCanvasContent,
            arrowBackOutline,
            closeModal,
            reference,
            confermaFirme,
        };
    },
};
