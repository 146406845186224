import axios from "axios";

export default {
  async getTecnici() {
    const data = new FormData();
    //data.append("limit", "50");
    data.append("where[users_active]", "1");
    data.append("where[]", `users_type IN (1,4)`);
    data.append("orderby", "users_last_name");
    data.append("orderdir", "desc");

    const response = await axios.post("rest/v1/search/users", data, {
      headers: {
        Authorization: process.env.VUE_APP_TOKEN,
      },
    });
    //console.log(response);
    return response.data.data;
  },
};
